<template>
    <div class="data_pagination">
        <el-pagination :current-page="getJournalData.current" :small="small" :pager-count="pagerCount"
            layout="prev, pager, next" prev-text="Previous" next-text="Next" :total="total"
            @current-change="onCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        total: {
            type: Number,
            default: 0
        }
    },
    computed: {
        ...mapGetters(['getJournalData']),
    },
    data() {
        return {
            pagerCount: 7,
            small: false
        }
    },
    methods: {
        updatePagerCount() {
            const width = window.innerWidth;
            this.pagerCount = width < 768 ? 5 : 7;
            this.small = width < 768 ? true : false;
        },
        onCurrentChange(val) {
            this.$emit('update:pagination', val)
        }
    },
    mounted() {
        this.updatePagerCount();
        window.addEventListener('resize', this.updatePagerCount);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updatePagerCount);
    },
}
</script>

<style lang="scss">
.data_pagination {
    .el-pagination {
        .btn-prev {
            border: 1.5px solid #eeeeee;
            margin-right: 14px;
        }

        .el-pager {
            .number {
                border: 1.5px solid #eeeeee;
                margin-right: 14px;
            }

            li.active {
                border-color: #1da6b8;
            }
        }

        .btn-next {
            border: 1.5px solid #eeeeee;
        }
    }
}
</style>